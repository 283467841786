import { throttle } from './utilities/throttleDebounce'

/* CSS */
import "@js/css"

/* Components */
import "@js/components/accordions"
import "@js/components/approachBgSwitching"
import "@js/components/browserDetect"
import "@js/components/cookieMessage"
import "@js/components/expandable"
// import "@js/components/experience/experience"
import "@js/components/header"
import "@js/components/hero"
import "@js/components/hScreenCalc"
import "@js/components/iconScroller"
import "@js/components/insightsFilter"
import "@js/components/insightsHover"
import "@js/components/introAnimation"
import "@js/components/lazyImagesScrollTrigger"
import "@js/components/lenis"
import "@js/components/logoStacker"
import "@js/components/lottie"
import "@js/components/parallax"
import "@js/components/projectsDefault"
import "@js/components/projectsHome"
import "@js/components/projectsOverview"
import "@js/components/radioRangeSwapper"
import "@js/components/responsiveVideo"
import "@js/components/reveal"
import "@js/components/safariFixes"
import "@js/components/savePresetForm"
import "@js/components/scrollToSection"
import "@js/components/scrollToTop"
import "@js/components/sliderIconScroller"
import "@js/components/sliderIconTextBlocks"
import "@js/components/sliderQuotes"
import "@js/components/sliderThreeColImages"
import "@js/components/teamFit"
import "@js/components/teamMember"
import "@js/components/videoFade"

/* Vendor */
import '@js/vendor/remus'

/* Experience script conditional load */
//
let experienceScript = document.querySelector('#experienceScript')

if (window.innerWidth >= 1024) {
  experienceScript.setAttribute('src', experienceScript.getAttribute('data-src'))
  experienceScript.removeAttribute('data-src')
}

window.addEventListener('resize', throttle(function() {
  if (window.innerWidth >= 1024) {
    if (!experienceScript.hasAttribute('src')) {
      experienceScript.setAttribute('src', experienceScript.getAttribute('data-src'))
      experienceScript.removeAttribute('data-src')
    }
  } else {
    if (experienceScript.hasAttribute('src')) {
      experienceScript.setAttribute('data-src', experienceScript.getAttribute('src'))
      experienceScript.removeAttribute('src')
    }
  }
}, 500))

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}
